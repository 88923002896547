<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="() => exitEditing()">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Select the type of IRS Forms the Business Filed from 2019-2021</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-6">
      <v-radio-group v-model="business_type">
        <v-radio
          v-for="(radio, i) in irsChoices"
          :key="i.id"
          :label="`${radio.name}`"
          :value="radio.id"
        ></v-radio>
      </v-radio-group>
      <v-text-field
        dense
        :disabled="business_type !== 4"
        v-model="peo_name"
        outlined
        label="Name of PEO"
        color="black"
      ></v-text-field>
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-btn text @click="() => exitEditing()">Cancel</v-btn>
          <v-btn @click="() => submit()" color="primary"> Save </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "IRSForms",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  methods: {
    exitEditing() {
      this.$emit("exitEditingIRSForms");
    },
    submit() {
      let payload = {
        business_type: this.business_type,
        peo_name: this.peo_name,
        uses_peo: 0
      }

      if (payload.business_type !== 4)
        delete payload.peo_name
      else {
        delete payload.business_type
        payload.uses_peo = 1
      }


      this.$emit("exitEditingIRSForms", payload);
    },
  },
  data: () => ({
    irsChoices: [
      { name: "IRS Form 941", id: 1 },
      { name: "IRS Form 943", id: 2 },
      { name: "IRS Form 944", id: 3 },
      { name: "Via PEO (Professional Employment Organization)", id: 4 },
    ],
    peo_name: "",
    business_type: 2,
  }),
  created() {
    if (this.client.uses_peo) {
      this.business_type = 4;
    } else {
      this.business_type = this.client.business_type;
    }
    this.peo_name = this.client.peo_name;
  },
};
</script>

<style scoped></style>