<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Owners And Family Members</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-6">
      <v-card-text style="padding: 0">
        <p class="font-weight-bold black--text">
          ERTC Regulations Require Employed Business Owners and Family Members be Removed from
          Consideration. Please Type One Name at a Time, Select Add After Each Name.
        </p>
        <p class="text--accent-2 black--text">
          In the box below, list the first and last name of all the business owners. Include any
          owners with a 50% or greater interest in the business.
        </p>
        <v-text-field :disabled="noOwners" id="client-owner-input-field" @keydown.enter="addMember(owner)" outlined v-model="owner"> </v-text-field>
        <p class="text--accent-2 black--text">
          In the box below type the first and last name of any W-2 employees that are related to the
          business owner who were employed between 2019-2021. Include all relatives and spouses
          (direct-relations, step-relations, distant relations).
        </p>
        <v-text-field :disabled="noOwners" @keydown.enter="addMember(member)" outlined v-model="member"> </v-text-field>
      </v-card-text>

      <v-card-actions class="mt-10">
        <v-row align="center" justify="space-between">
          <div>
            <v-switch
              label="No owners"
              v-model="noOwners"
            />
          </div>
          <div>
            <v-btn text @click="exitEditing">Cancel</v-btn>
            <v-btn color="primary" @click="addMember(owner ? owner : member)" :disabled="!this.member && !this.owner" :loading="loading">
              ADD
            </v-btn>
          </div>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-if="!familyLoaded" class="mt-6" type="chip"></v-skeleton-loader>
    <p class="text-body-1 mt-6" v-else-if="familyLoaded && !family.owner_family_list">
      The list is empty!
    </p>
    <v-row v-else>
      <v-col cols="12" md="7">
        <v-chip-group column class="mt-6">
          <v-chip
            close
            @click:close="deleteMember(i)"
            v-for="(item, i) in owner_family_array"
            :key="i"
          >
            {{ item }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "ClientOwnersAndFamilyCard",
  props: ["client"],
  data: () => ({
    loading: false,
    familyLoaded: false,
    family: {
      id: "",
      owner_family_list: "",
    },
    owner: "",
    noOwners: undefined,
    member: "",
  }),

  methods: {
    exitEditing() {
      this.$emit("exitEditingOwnersAndFamily");
    },
    getFamily() {
      return ClientsService.getRawOwners(this.client.id)
        .then((res) => {
          this.family = res;
        })
        .finally(() => (this.familyLoaded = true));
    },
    async updateFamily() {
      this.family.owner_family_list = this.owner_family_array.join("\n");
      this.loading = true;

      if (!this.family.id)
        await this.createFamily({ owner_family_list: this.family.owner_family_list });
      else await ClientsService.putRawClients(this.family, this.family.id);

      await this.getFamily();
      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: "Client was updated!",
        duration: 6000,
      });
      this.loading = false;
    },
    async createFamily(data) {
      await ClientsService.postRawClients(data, this.client.id);
    },
    separateStringIntoArrayBySymbol(string, symbol) {
      if (string) return string.split(symbol);
      return [];
    },
    async addMember(person) {
      if (!person) return;

      if (this.family.owner_family_list === 'false')
        this.family.owner_family_list = ''

      this.owner_family_array.push(person);
      this.member = "";
      this.owner = "";
      await this.updateFamily();
    },
    async deleteMember(i) {
      this.owner_family_array.splice(i, 1);
      await this.updateFamily();
    },
  },
  async mounted() {
    await this.getFamily();
    this.noOwners = this.family.owner_family_list === 'false'
  },
  computed: {
    owner_family_array() {
      if (this.family.owner_family_list === 'false')
        return []
      return this.separateStringIntoArrayBySymbol(this.family.owner_family_list, "\n");
    },
  },
  watch: {
    async noOwners(val) {
      if (val && this.family.owner_family_list !== 'false') {
        this.loading = true;
        this.family.owner_family_list = 'false'

        if (!this.family.id)
          await this.createFamily({ owner_family_list: this.family.owner_family_list });
        else await ClientsService.putRawClients(this.family, this.family.id);

        await this.getFamily();
        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Client was updated!",
          duration: 6000,
        });
        this.loading = false;
      }
    }
  }
};
</script>
