<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Did the Business Previously File for ERTC?</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-10">
      <p class="font-weight-bold">
        If yes, select the quarters in which the business previously filed for ERTC:
      </p>
        <v-checkbox
          :disabled="notPreviouslyFiled || loading"
          style="height: 30px"
          class="font-weight-bold"
          dense
          v-for="choice in ertcChoices"
          :key="choice.id"
          v-model="selectedQuarters"
          :label="choice.name"
          :value="choice.id"
        ></v-checkbox>
      <v-switch v-model="notPreviouslyFiled" label="Did not previously file" />
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-btn text @click="exitEditing">Cancel</v-btn>
          <v-btn :disabled="!notPreviouslyFiled && !selectedQuarters.length" :loading="saveloading" @click="putPreviouslyFilings" color="primary">
            Save</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/auth.service";
import ClientService from "@/services/clients.service";

export default {
  name: "IRSForms",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    ertcChoices: [
      { name: "Q4 2020", id: 4 },
      { name: "Q1 2021", id: 1 },
      { name: "Q2 2021", id: 2 },
      { name: "Q3 2021", id: 3 },
    ],
    clientId: null,
    selectedQuarters: [],
    notPreviouslyFiled: false,
    saveloading: false,
    loading: false,
  }),
  methods: {
    exitEditing() {
      this.$emit("exitEditingERTC");
    },
    getPreviouslyFilings(){
      this.loading = true;
      ClientService.getPreviouslyFilings(this.clientId).then((res) => {
        if (res.length !== 0) this.selectedQuarters = res
        else this.notPreviouslyFiled = true
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    putPreviouslyFilings(){
      this.saveloading = true
      return ClientService.putPreviouslyFilings(this.clientId, this.selectedQuarters).then((res) => {
        this.$func.popMessage("Saved Successfully.", "success");
        this.exitEditing();
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.saveloading = false
      })
    }
  },
  created() {
    this.clientId = this.$route.params.clientId
    this.getPreviouslyFilings()
  },
  watch: {
    notPreviouslyFiled(val) {
      if (val) this.selectedQuarters = []
    }
  },
};
</script>

<style scoped></style>
