<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Impact Test</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 py-6 px-10">
      <p class="mt-6">
        Please check each box that accurately describes the impact on the business. You must select at least one.
      </p>
      <v-row>
        <v-col v-for="impact in client.impact" :key="impact.id" cols="12">
          <v-checkbox
            style="padding: 0; margin: 0"
            v-model="data.impactChoices"
            :id="`impact-choice-id-${impact.id}`"
            :value="impact.id"
            color="primary"
            inset
            dense
            hide-details
          >
            <template v-slot:label>
              <span class="black--text">{{ impact.choice }}</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-textarea outlined v-model="data.otherImpactText" />
        </v-col>
        <v-col cols="12">
          <p class="font-weight-bold">
            Did business owner sign off that to their knowledge that the listed experiences
            nominally impact their business by 10% or more?
          </p>
          <p class="font-weight-bold">
            Instructions: Verify on Financial Checklist. If owner did not sign the appropriate
            field. Please have owner sign and make a new profile.
          </p>
        </v-col>
        <v-col class="d-flex flex-row justify-space-around">
          <v-radio-group row v-model="data.signedKnowledge">
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-btn text @click="exitEditing">Cancel</v-btn>
          <v-btn :loading="saveLoading" @click="updateImpactTest" color="primary">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "ImpactTest",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  methods: {
    exitEditing(refresh) {
      this.$emit("exitEditingImpactTest", refresh);
    },
    updateImpactTest() {
      this.saveLoading = true;
      let clientPayload = {
        impact_verified: this.data.signedKnowledge,
      }

      Promise.all([
        ClientsService.editClient(this.client.id, clientPayload),
        ClientsService.putBusinessImpact(this.client.id, this.data.impactChoices),
      ]).then(() => {
        this.$func.popMessage("Saved Successfully.", "success")
        this.exitEditing(true)
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.saveLoading = false
      })

    }
  },
  data: () => ({
    revenueReductionYearChoices: [
      { year: "2019", value: 2019 },
      { year: "2020", value: 2020 },
      { year: "2021", value: 2021 },
    ],
    revenueLoading: false,
    saveLoading: false,
    data: {
      revenueReductionYears: [],
      impactChoices: [],
      otherImpactText: "",
      signedKnowledge: false,
    },
  }),
  created() {
    this.client.impact.forEach((e) => {
      if (e.selected) this.data.impactChoices.push(e.id);
    });
    this.data.signedKnowledge = !!this.client.impact_verified;
  },
  watch: {
    "data.noOtherCompanies"(val) {
      if (val) {
        this.data.legalBusinessName = "";
        this.data.ein = "";
        this.data.numOfEmployees = "";
      }
    },
  },
};
</script>

<style scoped></style>