<template>
  <ClientInfoEditCard
    v-if="editing"
    :client="client"
    :editLoading="editLoading"
    :editingValue="editingValue"
    @exitEditing="exitEditing"
    @editClient="editClient"
    @autocomplete-input="getNaicsCodes"
    :naicsItems="naicsItems"
  />
  <ClientAddressEditCard
    v-else-if="editingAddress"
    :client="client"
    :addressInputs="editingValue"
    :editLoading="editLoading"
    @exitEditingAddress="exitEditingAddress"
    @editClientAddress="editClientAddress"
  />
  <ClientPPPRoundCard
    v-else-if="editingRound"
    :client="client"
    :round="editingValue"
    @exitEditingRound="exitEditingRound"
  />
  <ClientOwnersAndFamilyCard
    v-else-if="editingOwnersAndFamily"
    :client="client"
    @exitEditingOwnersAndFamily="exitEditingOwnersAndFamily"
  />
  <IRSForms
    v-else-if="editingIRSForms"
    :client="client"
    @exitEditingIRSForms="exitEditingIRSForms"
  />
  <ERTCFile
    v-else-if="editingERTC"
    :client="client"
    @exitEditingERTC="exitEditingERTC"
  />
  <Ownership
    v-else-if="editingOtherOwnership"
    :client="client"
    @exitEditingOtherOwnership="exitEditingOtherOwnership"
  />
  <RevenueReduction
    v-else-if="editingRevenueReduction"
    :client="client"
    @exitEditingRevenueReduction="exitEditingRevenueReduction"
  />
  <ImpactTest
    v-else-if="editingImpactTest"
    :client="client"
    @exitEditingImpactTest="exitEditingImpactTest"
  />
  <v-container v-else style="padding: 0">
    <v-overlay :value="loadingScreen">
      <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row class="mt-6" no-gutters v-if="!loadingScreen" justify="space-between">
      <v-col cols="12" md="6" sm="12" class="mb-4">
        <v-alert class="text-center" type="warning" v-if="onLitigationHold">Client has at least one quarter in litigation hold!</v-alert>
        <v-row class="mb-2" v-if="usersRole === 1">
          <v-col class="align-center" cols="12">
            <v-alert
              style="background: #dfc748"
              class="md-primary tour-guide-ribbon px-10 py-5 mb-1"
            >
              <v-row justify="space-between">
                <v-col cols="auto">
                  <span class="font-weight-bold white--text"> Current Status: </span>
                  <span id="workflow-status" class="white--text">{{ currStatus }}</span>
                </v-col>
                <v-col cols="auto" class="align-center" style="font-size: 0.85rem">
                  <div v-if="currStage !== 20">
                    <span class="white--text font-weight-bold">Last Evaluated:</span>
                    <span class="white--text">&nbsp;{{ lastEvalAt }}</span>
                    <v-btn
                      small
                      id="client-stage-refresh-btn"
                      :loading="loadingRefresh"
                      @click="fetchWorkflow"
                      text
                      color="blue"
                      class="ml-2"
                      >Refresh
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      small
                      :loading="loadingResubmit"
                      @click="resubmit"
                      color="error"
                      class="ml-2"
                    >Resubmit
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-alert>
            <v-alert style="background: #ffae6b" class="md-primary tour-guide-ribbon px-10 py-5">
              <v-row>
                <v-col>
                  <span class="font-weight-bold white--text"> Next Step: </span>
                  <span class="white--text" v-if="nextStep">{{ nextStep }}</span>
                  <span class="white--text" v-else>Nothing required at this time.</span>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <ClientHeader hide-divider :client="client" />
        <div class="d-flex flex-wrap align-center flex-sm-row justify-space-between mb-6 d-md-none">
          <v-btn color="primary" v-if="client.is_adopted === 1" @click="deleteAdoption" class="col col-12 col-sm-4 mb-2 mb-sm-0">Unadopt</v-btn>
          <v-chip class="toggle col col-auto " color="#BEC7E6"
            >ACTIVE &nbsp;<v-switch
              :loading="clientToggleLoading"
              v-model="activeClient"
              @click="patchClient"
            />
          </v-chip>

          <v-chip class="toggle col col-auto" color="#BEC7E6"
            >ON HOLD &nbsp;<v-switch
              :loading="clientHoldLoading"
              v-model="switchClientOnHold"
              @click="switchOnHold"
            />
          </v-chip>
        </div>
        <br />
        <ClientInformation
          title="Business Details"
          :details="businessDetails"
          :editItem="editItem"
        />
        <br />
        <ClientInformation title="Contact Details" :details="contactDetails" :editItem="editItem" />
        <br />
        <ClientInformation
          title="ERTC Information"
          :details="ERTCInformation"
          type="ertc"
          :generatedPackages="generatedPackages"
          :generatePackageButtonDisabled="generatePackageButtonDisabled"
          :usersRole="usersRole"
          :client="client"
          @updatePackages="getAllPackages"
        />
      </v-col>
      <v-col v-if="!loadingScreen" cols="12" md="6" sm="12" class="pl-5">
        <div class="mb-6 d-flex align-center justify-end">
          <v-btn class="mr-3" v-if="client.is_adopted === 1" @click="deleteAdoption" color="primary">Unadopt</v-btn>
          <v-chip class="toggle d-none d-md-block mr-3" color="#BEC7E6"
            >ACTIVE &nbsp;<v-switch
              :loading="clientToggleLoading"
              v-model="activeClient"
              @click="patchClient"
            />
          </v-chip>

          <v-chip class="toggle d-none d-md-block" color="#BEC7E6"
            >ON HOLD &nbsp;<v-switch
              :loading="clientHoldLoading"
              v-model="switchClientOnHold"
              @click="switchOnHold"
            />
          </v-chip>
        </div>
        <ClientSupportTickets type="mini" :clientId="client.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/auth.service";
import { mapGetters } from "vuex";
import ClientService from "@/services/clients.service";
import TaxService from "@/services/tax.service";
import LawService from "@/services/law.service";
import OwnersService from "@/services/owners.service";
import ClientInfoEditCard from "@/components/Client/Profile/ClientInfoEditCard.vue";
import ClientAddressEditCard from "@/components/Client/Profile/ClientAddressEditCard.vue";
import ClientSupportTickets from "@/components/Client/ClientSupportTickets.vue";
import ClientPPPRoundCard from "@/components/Client/Profile/ClientPPPRoundCard.vue";
import ClientOwnersAndFamilyCard from "@/components/Client/Profile/ClientOwnersAndFamilyCard.vue";
import ClientInformation from "@/components/Client/Profile/ClientInformation.vue";
import PackageService from "@/services/packages.service";
import IRSForms from "@/components/Client/Profile/IRSForms.vue";
import ERTCFile from "@/components/Client/Profile/ERTCFile.vue";
import Ownership from "@/components/Client/Profile/Ownership.vue";
import RevenueReduction from "@/components/Client/Profile/RevenueReduction.vue";
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientsService from "@/services/clients.service";
import AdoptionsService from "@/services/adoptions.service";
import ImpactTest from "@/components/Client/Profile/ImpactTest.vue";

export default {
  name: "ClientProfile",
  components: {
    ImpactTest,
    ClientInfoEditCard,
    ClientSupportTickets,
    ClientAddressEditCard,
    ClientPPPRoundCard,
    ClientOwnersAndFamilyCard,
    ClientInformation,
    IRSForms,
    ERTCFile,
    Ownership,
    RevenueReduction,
    ClientHeader
  },
  data: () => ({
    openDialog: false,
    usersRole: 99,
    hasOwners: false,
    rules: {
      required: (value) => !!value || "This field is required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      ein: (value) => {
        const pattern = /[0-9]{2}-[0-9]{7}/;
        return pattern.test(value) || "Invalid ein";
      },
      zip: (value) => {
        const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return pattern.test(value) || "Invalid ZIP code";
      },
      contingency_rate: (value) => {
        if (value < 10) {
          return "Rate is less then 10%.Please contact support.";
        }
      },
    },
    editing: false,
    editingValue: {
      key: "",
      value: "",
      options: [],
    },
    editLoading: false,
    editingAddress: false,
    editingRound: false,
    editingOwnersAndFamily: false,
    editingIRSForms: false,
    editingERTC: false,
    editingOtherOwnership: false,
    editingRevenueReduction: false,
    editingImpactTest: false,
    loadingScreen: true,
    profileDetails: [],
    firmDetails: [],
    roundDetails: [],
    addressDetails: [],
    businessDetails: [],
    contactDetails: [],
    ERTCInformation: [],
    taxes: [],
    laws: [],
    rounds: [],
    activeClient: null,
    clientToggleLoading: false,
    switchClientOnHold: false,
    clientHoldLoading: false,
    roundsDataLoaded: false,
    attorneys: [],
    isTaxDisabled: false,
    isEmployeeDisabled: false,
    generatedPackages: [],
    generationInProgress: false,
    clientId: "",
    originalOwners: [],
    client: {
      legal_name: "",
      trade_name: "",
      naic: "",
      ein: "",
      contact_phone: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      contact_first: "",
      contact_last: "",
      contact_email: "",
      attorney_of_record: "",
      owner: "",
      tax_firm: "",
      managing_firm: "",
      contingency_rate: "",
      contact_salutation: "",
      business_type: "",
      uses_peo: 0,
    },
    naicsItems: [],
    salutationList: [
      {
        name: "Mr.",
        value: "Mr.",
      },
      {
        name: "Mrs.",
        value: "Mrs.",
      },
      {
        name: "Ms.",
        value: "Ms.",
      },
      {
        name: "Dr.",
        value: "Dr.",
      },
    ],
    currStatus: "",
    currStage: 910,
    lastEvalAt: 0,
    nextStep: "",
    loadingRefresh: false,
    loadingResubmit: false,
    family: {
      owner_family_list: ""
    },
    notPreviouslyFiled: "",
    selectedQuarters: [],
    revenueReduction: [],
    onLitigationHold: false,
  }),

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    salutation_err() {
      return this.handleErrors(this.$v.client.contact_salutation, "Salutation Field is required.");
    },
    tax_firmErrors() {
      return this.handleErrors(this.$v.client.tax_firm, "Tax Firm Field is required.");
    },
    managing_firmErrors() {
      return this.handleErrors(this.$v.client.managing_firm, "Managing Firm Field is required.");
    },
    roundOne() {
      return this.rounds.filter((round) => round.number === 1)[0];
    },

    roundTwo() {
      return this.rounds.filter((round) => round.number === 2)[0];
    },
    generatePackageButtonDisabled() {
      return (
        (!this.roundOne && !this.roundTwo) ||
        (this.originalOwners.length === 0 && this.hasOwners === false) ||
        !this.client.csv_upload.id
      );
    },
  },
  methods: {
    deleteAdoption(){
      this.loadingScreen = true;
      AdoptionsService.deleteAdoption(this.client.id).then(() => {
        this.getCurrentClient(this.client.id);
      }).finally(() => this.loadingScreen = false);
    },
    getUser() {
      return AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
        this.usersRole = res.data.role_id;
      });
    },
    getCurrentClient(id) {
      return ClientService.getOneClient(id)
        .then((data) => {
          this.client = data;
          this.activeClient = this.client.active === 1 ? true : false;
          this.currStatus = data.workflow.stage_friendly_name;
          this.currStage = data.workflow.stage;
          this.lastEvalAt = data.workflow.workflow_last_evaluated;
          this.nextStep = data.workflow.next_step;
          this.switchClientOnHold = data.on_hold == 1 ? true : false;
          this.updateDetails();
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}. Please, try again.`,
            duration: 4000,
            mode: "fail",
          });
        });
    },
    getAllTaxes() {
      return TaxService.getTaxes().then((data) => {
        this.taxes = data;
        let clientsTaxFirmExists = false;
        this.taxes.forEach((e) => {
          if (e.id === this.client.tax_firm.id) clientsTaxFirmExists = true;
        });
        if (!clientsTaxFirmExists) this.taxes.push(this.client.tax_firm);
        this.updateDetails();
      });
    },
    getAllLaws() {
      return LawService.getLaws().then((data) => {
        this.laws = data;
        this.updateDetails();
      });
    },
    loadAttorneys() {
      if (this.usersRole === 1) {
        return ClientService.getEntitiesFiltered(`${this.client.managing_firm.id}`).then((res) => {
          res.users.map((e) => {
            if (e.role_id === 7 || e.role_id === 4) this.attorneys.push(e);
          });
          this.attorneys.push(this.client.attorney_of_record);
        });
      }
      return ClientService.getEntitiesFiltered("my").then((res) => {
        res.users.map((e) => {
          if (e.role_id === 7 || e.role_id === 4) this.attorneys.push(e);
        });
        this.attorneys.push(this.client.attorney_of_record);
      });
    },
    getAllRounds() {
      return ClientService.getRounds({ clientId: this.clientId })
        .then((data) => {
          this.rounds = data;
          this.roundDetails = [
            { key: "2020 Round of PPP", value: this.roundOne, number: 1 },
            { key: "2021 Round of PPP", value: this.roundTwo, number: 2 },
          ];
          this.roundsDataLoaded = true;
        })
        .catch((error) => {
          this.rounds = [];
          this.roundsDataLoaded = true;
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}`,
            duration: 4000,
            mode: "fail",
          });
        });
    },
    getNaicsCodes(value) {
      ClientService.getNaicsCodes(value).then((res) => {
        this.naicsItems = res;
      });
    },
    getAllPackages() {
      return PackageService.getPackages(this.client.id).then((data) => {
        if (data) {
          this.generatedPackages = data;
        }
      });
    },
    patchClient() {
      this.clientToggleLoading = true;
      ClientService.editClient(this.client.id, {
        active: this.activeClient === true ? 1 : 0,
      }).finally(() => (this.clientToggleLoading = false))
    },
    switchOnHold() {
      this.patchClientOnHold(this.client.id, this.switchClientOnHold);
    },
    patchClientOnHold(id, isOnHold) {
      const data = { on_hold: isOnHold };
      this.clientHoldLoading = true;
      return ClientService.patchClientOnHold(id, data)
        .then(() => {})
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        })
        .finally(() => (this.clientHoldLoading = false));
    },
    editItem(item) {
      switch (item.key) {
        case "Address":
          this.editAddress();
          break;
        default:
          this.editing = true;
          this.editingValue = item;
      }
    },
    editRound(item) {
      this.editingRound = true;
      this.editingValue = item;
    },
    editAddress() {
      this.editingAddress = true;
      this.editingValue = this.addressDetails;
    },
    editOwnersAndFamily() {
      this.editingOwnersAndFamily = true;
    },
    editIRSForms() {
      this.editingIRSForms = true;
    },
    editERTC() {
      this.editingERTC = true;
    },
    editOtherOwnership() {
      this.editingOtherOwnership = true;
    },
    editRevenueReduction() {
      this.editingRevenueReduction = true;
    },
    editImpactTest() {
      this.editingImpactTest = true
    },
    exitEditing() {
      this.editing = false;
      this.client[this.editingValue.refKey] = this.editingValue.value;
      this.editingValue = {};
    },
    exitEditingAddress() {
      this.editingAddress = false;
      for (let i = 0; i < this.editingValue.length; i++)
        this.client[this.editingValue[i].refKey] = this.editingValue[i].value;
      this.editingValue = {};
    },
    async exitEditingRound() {
      this.editingRound = false;
      this.editingValue = {};
      await this.getAllRounds();
      this.updateDetails();
    },
    async exitEditingOwnersAndFamily() {
      this.editingOwnersAndFamily = false;
      await this.getFamily();
      this.updateDetails();
    },
    exitEditingIRSForms(changes) {
      if (changes)
         ClientService.editClient(this.client.id, changes)
            .then(() => {
              this.getCurrentClient(this.client.id);
              this.$store.dispatch("snackbar/showSnackbarMessage", {
                message: "Client was updated!",
                duration: 6000,
              });
            })
            .catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, "fail");
              this.editLoading = false;
            });

      this.editingIRSForms = false;
    },
    async exitEditingERTC() {
      this.editingERTC = false;
      await this.getPreviouslyFilings();
      this.updateDetails();
    },
    exitEditingOtherOwnership() {
      this.editingOtherOwnership = false;
    },
    async exitEditingRevenueReduction(refresh) {
      this.editingRevenueReduction = false;
      if (refresh) {
        await this.getRevenueReduction();
        this.getCurrentClient(this.client.id);
      }
    },
    async exitEditingImpactTest(refresh) {
      this.editingImpactTest = false;
      if (refresh) {
        this.getCurrentClient(this.client.id);
      }
    },
    editClient(value) {
      if (
        value &&
        this.client[this.editingValue.refKey] &&
        value === this.client[this.editingValue.refKey].id
      )
        return;

      if (this.editingValue.value !== this.client[this.editingValue.refKey] || value) {
        this.editLoading = true;
        let changes = {
          [this.editingValue.refKey]: value ? value : this.client[this.editingValue.refKey],
        };
        if ("naic" in changes) {
          changes = Object.fromEntries(
            Object.entries(changes).map(([key, value]) =>
              key === "naic" ? [`naics_code`, value] : [`${key}`, value]
            )
          );
        }
        return ClientService.editClient(this.client.id, changes)
          .then(() => {
            this.getCurrentClient(this.client.id);
            this.editLoading = false;
            this.exitEditing();
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: "Client was updated!",
              duration: 6000,
            });
          })
          .catch((error) => {
            this.$func.popMessage(`${error.response.data.error}`, "fail");
            this.editLoading = false;
          });
      }
    },
    editClientAddress() {
      let changed = false;
      for (let i = 0; i < this.editingValue.length; i++) {
        if (this.client[this.editingValue[i].refKey] !== this.editingValue[i].value) changed = true;
      }
      if (!changed) return;

      const changes = this.editingValue.reduce((acc, x) => {
        acc[x.refKey] = this.client[x.refKey];
        return acc;
      }, {});

      this.editLoading = true;
      return ClientService.editClient(this.client.id, changes)
        .then(() => {
          this.getCurrentClient(this.client.id);
          this.editLoading = false;
          this.exitEditingAddress();
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Client was updated!",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
          this.editLoading = false;
        });
    },

    getOwners() {
      return OwnersService.getOwners({ clientId: this.clientId })
        .then((data) => {
          this.originalOwners = data.owners;
          this.hasOwners = data.has_owners;
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });
    },

    handleErrors(valObject, errorMsg) {
      const errors = [];

      if (!valObject.$error) return errors;
      !valObject.required && errors.push(errorMsg);
      return errors;
    },
    updateDetails() {
      this.client.attorney_of_record.name =
        this.client.attorney_of_record.first + " " + this.client.attorney_of_record.last;

      this.businessDetails = [
        {
          key: "Legal Business name",
          value: this.client.legal_name,
          filled: this.checkFilled([this.client.legal_name]),
          refKey: "legal_name",
          rules: [this.rules.required],
        },
        {
          key: "Trade name",
          value: this.client.trade_name,
          filled: this.checkFilled([this.client.trade_name]),
          refKey: "trade_name",
          rules: [this.rules.required],
        },
        {
          key: "EIN",
          value: this.client.ein,
          filled: this.checkFilled([this.client.ein]),
          refKey: "ein",
          rules: [this.rules.required, this.rules.ein],
        },
        {
          key: "NAICS Codes",
          value: this.client.naics_code,
          filled: this.checkFilled([this.client.naics_code]),
          refKey: "naic",
          rules: [this.rules.required, this.rules.naic],
          options2: this.naicsItems,
        },
      ];
      this.contactDetails = [
        {
          key: "Contact Phone",
          value: this.client.contact_phone,
          filled: this.checkFilled([this.client.contact_phone]),
          refKey: "contact_phone",
          rules: [this.rules.required],
        },
        {
          key: "Address",
          value: this.client.street,
          filled: this.checkFilled(this.addressDetails.map(detail => detail.value)),
          refKey: "street",
          rules: [this.rules.required],
        },
        {
          key: "Salutation",
          value: this.client.contact_salutation,
          filled: this.checkFilled([this.client.contact_salutation]),
          refKey: "contact_salutation",
          rules: [this.rules.required],
          options: this.salutationList,
        },
        {
          key: "Contact first name",
          value: this.client.contact_first,
          filled: this.checkFilled([this.client.contact_first]),
          refKey: "contact_first",
          rules: [this.rules.required],
        },
        {
          key: "Contact last name",
          value: this.client.contact_last,
          filled: this.checkFilled([this.client.contact_last]),
          refKey: "contact_last",
          rules: [this.rules.required],
        },
        {
          key: "Email for the point of contact",
          value: this.client.contact_email,
          filled: this.checkFilled([this.client.contact_email]),
          refKey: "contact_email",
          rules: [this.rules.required, this.rules.email],
        },
        {
          key: "Contingency Rate (in points)",
          value: this.client.contingency_rate,
          filled: this.checkFilled([this.client.contingency_rate]),
          refKey: "contingency_rate",
          rules: [this.rules.required, this.rules.contingency_rate],
        },
        {
          key: "Tax Firm",
          value: this.client.tax_firm,
          filled: this.checkFilled([this.client.tax_firm]),
          refKey: "tax_firm",
          rules: [this.rules.required],
          options: this.taxes,
        },
        {
          key: "Managing Firm",
          value: this.client.managing_firm,
          filled: this.checkFilled([this.client.managing_firm]),
          refKey: "managing_firm",
          rules: [this.rules.required],
          options: this.laws,
        },
        {
          key: "Attorney of Record",
          value: this.client.attorney_of_record,
          filled: this.checkFilled([this.client.attorney_of_record]),
          refKey: "attorney_of_record",
          rules: [this.rules.required],
          options: this.attorneys,
        },
      ];

      this.addressDetails = [
        {
          key: "Street",
          value: this.client.street,
          refKey: "street",
          rules: [this.rules.required],
        },
        {
          key: "City",
          value: this.client.city,
          refKey: "city",
          rules: [this.rules.required],
        },
        {
          key: "State",
          value: this.client.state,
          refKey: "state",
          rules: [this.rules.required],
        },
        {
          key: "ZIP",
          value: this.client.zip,
          refKey: "zip",
          rules: [this.rules.required, this.rules.zip],
        },
      ];

      this.ERTCInformation = [
        {
          key: "Owners & Family Members",
          value: null,
          filled: this.checkFilled([this.family.owner_family_list]),
          editFunc: this.editOwnersAndFamily,
        },
        {
          key: "Originally Filed IRS Forms",
          filled: this.checkFilled([this.client.business_type]),
          value: null,
          editFunc: this.editIRSForms,
        },
        {
          key: "Did the Business Previously File for ERTC?",
          filled: this.selectedQuarters.length !== 0,
          tickStyle: "opacity: 0.5",
          value: null,
          editFunc: this.editERTC,
        },
        {
          key: "Ownership in Any Other Company During These Quarters?",
          filled: true,
          value: null,
          editFunc: this.editOtherOwnership,
        },
        {
          key: "2020 Round of PPP",
          value: this.roundOne ?? "NO ROUNDS DECLARED!",
          filled: this.checkFilled([this.roundOne]),
          number: 1,
          editFunc: this.editRound,
        },
        {
          key: "2021 Round of PPP",
          value: this.roundTwo ?? "NO ROUNDS DECLARED!",
          filled: this.checkFilled([this.roundTwo]),
          number: 2,
          editFunc: this.editRound,
        },
        {
          key: "Revenue Reduction",
          filled: this.revenueReduction.length,
          value: null,
          editFunc: this.editRevenueReduction,
        },
        {
          key: 'Impact Test',
          filled: this.client.impact.some(impact => impact.selected),
          value: null,
          editFunc: this.editImpactTest
        }
      ];
    },
    checkFilled(items) {
      return items.every(item => item !== "" && item !== null && item !== undefined)
    },
    getFamily() {
      return ClientsService.getRawOwners(this.client.id)
        .then((res) => {
          this.family = res;
          this.updateDetails()
        })
    },
    getPreviouslyFilings(){
      return ClientService.getPreviouslyFilings(this.client.id).then((res) => {
        this.selectedQuarters = res
      })
    },
    getRevenueReduction() {
      return ClientsService.getClientRevenuereduction(this.client.id)
        .then((res) => {
          this.revenueReduction = res
          this.updateDetails()
        })
    },
    fetchWorkflow() {
      this.loadingRefresh = true;
      ClientService.refreshWorkflowStatus(this.client.id)
        .then((res) => {
          this.currStatus = res.stage_friendly_name;
          this.currStage = res.stage;
          this.lastEvalAt = res.workflow_last_evaluated;
          this.nextStep = res.next_step;
        })
        .finally(() => (this.loadingRefresh = false));
    },
    resubmit() {
      this.loadingResubmit = true;
      ClientService.resubmitStatus(this.client.id)
        .then((res) => {
          this.currStatus = res.stage_friendly_name;
          this.currStage = res.stage;
          this.lastEvalAt = res.workflow_last_evaluated;
          this.nextStep = res.next_step;
        })
        .finally(() => (this.loadingResubmit = false));
    },
    getChecks(){
      ClientService.getChecks(this.clientId).then((res) => {
        res.forEach(e=> {if (e.litigation_hold) this.onLitigationHold = true})
      })
    }
  },
  created() {
    this.$router.onReady(async () => {
      this.clientId = this.$route.params.clientId;
      await this.getUser();
      await this.getCurrentClient(this.clientId);
      this.getAllRounds();
      this.getAllTaxes();
      this.getAllLaws();
      this.loadAttorneys();
      this.getOwners();
      this.getFamily();
      this.getPreviouslyFilings();
      this.getRevenueReduction();
      this.getChecks()

      this.loadingScreen = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #00000017;
}

.select-meny .my-select {
  margin: 0 !important;
}

.no-csv-message {
  color: white;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: #ff5252;
  border: 1px solid gray;
}

.toggle {
  padding: 12px 12px 12px 28px;
  height: 50px;
  border-radius: 50px;
}

.value {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.key {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.complete {
  background-color: lightgreen;
}

.incomplete {
  background-color: lightpink;
}
</style>
