<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Revenue Reduction</h3>
      </v-col>
    </v-row>
    <v-card width="1200px" class="mt-6 py-6 px-10">
      <p>
        Please enter your business's <b>GROSS REVENUE</b> for all of the applicable quarters and years.
      </p>
      <v-row v-if="revenueLoading">
        <v-col>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </v-col>
      </v-row>
      <div v-else>
        <v-row class="d-none d-md-flex text-center">
          <v-col cols="2"></v-col>
          <v-col cols="2">Q1</v-col>
          <v-col cols="2">Q2</v-col>
          <v-col cols="2">Q3</v-col>
          <v-col cols="2">Q4</v-col>
        </v-row>
        <v-row align="center">
          <v-row class="text-center" v-for="key in Object.keys(fields)">
            <v-col cols="12" md="2"><span class="font-italic">{{ key }}</span></v-col>
            <v-col cols="2" v-for="field in fields[key]">
              <v-text-field v-restrict.number.decimal type="number"
                            :disabled="field.year === 2021 && field.quarter === 4"
                            :class="{ coloredField: field.qualified === 1 }"
                            prefix="$" dense outlined hide-details v-model="field.amount">
              </v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-btn text @click="exitEditing">Cancel</v-btn>
          <v-btn :loading="saveLoading" @click="updateRevenueReduction" :disabled="!valid" color="primary">
            Save
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "IRSForms",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  methods: {
    exitEditing(refresh) {
      this.$emit("exitEditingRevenueReduction", refresh);
    },
    getRevenueReduction() {
      this.revenueLoading = true;
      return ClientsService.getClientRevenuereduction(this.client.id)
          .then((res) => {
            if (res.length) {
              this.fields = res
              if (this.fields[4].quarter !== 1) {
                this.fields.splice(4, 0, {
                  "year": 2020,
                  "quarter": 1,
                  "amount": "0.00"
                })
              }
              // Group by year
              this.fields = this.fields.reduce((acc, curr) => {
                acc[curr.year] = acc[curr.year] || []
                acc[curr.year].push(curr)
                return acc
              }, {});
              // Check if yaer has all quarters
              Object.keys(this.fields).forEach(e => {
                for (let i = 1; i <= 4; i++) {
                  if (!this.fields[e].find(field => field.quarter === i)) {
                    if (e == 2021 && i == 4) continue
                    this.fields[e].push({
                      "year": parseInt(e),
                      "quarter": i,
                      "amount": ""
                    })
                  }
                }
                this.fields[e] = this.fields[e].sort((a, b) => a.quarter - b.quarter)
              })
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.revenueLoading = false));
    },
    updateRevenueReduction() {
      this.saveLoading = true;
      let payload = []
      Object.keys(this.fields).forEach(e => {
        this.fields[e].forEach(field => {
          payload.push({
            year: field.year,
            quarter: field.quarter,
            amount: field.amount
          })
        })
      })

      ClientsService.putRevenuereduction(this.client.id, payload)
          .then(() => {
            this.$func.popMessage("Saved Successfully.", "success")
            this.getRevenueReduction()
          }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.saveLoading = false
      })

    }
  },
  data: () => ({
    valid: true,
    fields: {
      2019: [{
        "year": 2019,
        "quarter": 1,
        "amount": ""
      },
        {
          "year": 2019,
          "quarter": 2,
          "amount": ""
        },
        {
          "year": 2019,
          "quarter": 3,
          "amount": ""
        },
        {
          "year": 2019,
          "quarter": 4,
          "amount": ""
        }],
      2020: [{
        "year": 2020,
        "quarter": 1,
        "amount": ""
      },
        {
          "year": 2020,
          "quarter": 2,
          "amount": ""
        },
        {
          "year": 2020,
          "quarter": 3,
          "amount": ""
        },
        {
          "year": 2020,
          "quarter": 4,
          "amount": ""
        }],
      2021: [{
        "year": 2021,
        "quarter": 1,
        "amount": ""
      },
        {
          "year": 2021,
          "quarter": 2,
          "amount": ""
        },
        {
          "year": 2021,
          "quarter": 3,
          "amount": ""
        }
      ]
    },
    revenueLoading: false,
    saveLoading: false,
  }),
  watch: {
    fields: {
      handler: function () {
        this.valid = true
        Object.keys(this.fields).forEach(e => {
          this.fields[e].forEach(field => {
            if (!field.amount) {
              this.valid = false
            }
          })
        })
      },
      deep: true,
    },
  },
  created() {
    this.getRevenueReduction();
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.coloredField {
  background-color: #4CAF50;
}
</style>
