<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>{{ editingValue.key }}</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-9">
      <v-card-text style="padding: 0">
        <v-form v-model="isValid">
          <v-select
            v-if="editingValue.options"
            :append-icon="'mdi-arrow-down'"
            outlined
            :items="editingValue.options"
            :rules="editingValue.rules"
            :value="editingValue.value"
            :item-text="'name'"
            :item-value="'id'"
            @change="
              (value) => {
                selected = value;
              }
            "
          >
          </v-select>

          <div v-else-if="editingValue.options2">
            <v-autocomplete
              v-model="client[editingValue.refKey]"
              :no-data-text="
                searchedAlready
                  ? 'Try entering more or different search terms.'
                  : 'When your search has a match, they will display here.'
              "
              @change="handleAutocompleteChange"
              :items="naicsItems"
              :item-text="'code'"
              :item-value="'id'"
              :loading="loadingNaics"
              no-filter
              @update:search-input="emitAutocompleteNaicValue"
              :label="initialNaic ?? 'Naics Codes'"
            >
            </v-autocomplete>
          </div>
          <v-text-field
            v-else
            :label="editingValue.key"
            :rules="editingValue.rules"
            v-model="client[editingValue.refKey]"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-switch
            :disabled="sameAsBusinessName"
            v-model="sameAsBusinessName"
            label="Same as Legal Business Name"
            v-if="editingValue.refKey === 'trade_name'"
          />
          <v-btn text @click="exitEditing"> Cancel</v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="editClient" :loading="editLoading">
            Save
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ClientInfoEditCard",
  props: ["editingValue", "editLoading", "client", "naicsItems"],
  data: () => ({
    isValid: true,
    selected: "",
    selectedNaicItem: null,
    searchedAlready: false,
    loadingNaics: false,
    initialNaic: "",
  }),
  methods: {
    exitEditing() {
      this.$emit("exitEditing");
    },
    editClient() {
      this.$emit("editClient", this.selected);
    },
    emitAutocompleteNaicValue(value) {
      if (!value) return;
      this.loadingNaics = true;
      this.searchedAlready = true;
      this.$emit("autocomplete-input", value);
    },
    handleAutocompleteChange(selectedItem) {
      this.selectedNaicItem = selectedItem;
    },
  },
  watch: {
    naicsItems() {
      this.loadingNaics = false;
    },
  },
  created() {
    this.initialNaic = this.client[this.editingValue.refKey];
  },
  computed: {
    sameAsBusinessName: {
      set(val) {
        if (val) this.client[this.editingValue.refKey] = this.client["legal_name"];
      },
      get() {
        return this.client["trade_name"] === this.client["legal_name"];
      },
    },
  },
};
</script>
