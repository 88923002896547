<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Did the Business Owner Have Ownership in Any Other Company These Quarters?</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-10">
      <p class="font-weight-bold">If yes, list the EIN(s), & the number of employees:</p>
      <v-skeleton-loader v-if="loadingBusiness" type="card" />
      <v-card v-else v-for="(company, i) in companies" :key="`C${i}`" class="mt-4">
        <v-card-text>
          <v-form v-model="validForm[i]">
            <v-row dense>
              <v-col class="text-body-1 mt-2 black--text" cols="6">EIN:</v-col>
              <v-col class="d-flex justify-end" cols="6">
                <v-btn :disabled="noOtherCompanies" icon>
                  <v-icon @click="deleteCompany(i)" class="black--text">mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :disabled="noOtherCompanies"
                  maxLength="10"
                  counter="10"
                  v-model="company.ein"
                  :rules="[company.ein ? rules.ein : () => true]"
                  dense
                />
              </v-col>
            </v-row>
            <div class="text-body-1 mt-5 black--text">Employee counts per quarter:</div>
            <v-row class="mt-3" justify="center">
              <v-col
                style="gap: 10px"
                v-for="(quarter, j) in company.employees"
                :key="`Q${j}`"
                class="d-flex flex-column"
                cols="3"
              >
                <div>
                  <v-text-field
                    v-model="quarter.count"
                    :disabled="noOtherCompanies"
                    class="centered-input"
                    type="number"
                    min="1"
                    hide-details
                    dense
                  />
                </div>
                <div class="text-caption text-center">
                  {{ `${quarter.year}-Q${quarter.quarter}` }}
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-switch v-model="noOtherCompanies" label="Does not own any other companies" />
      <v-card-actions class="mt-10">
        <v-row align="center" justify="end">
          <v-btn text @click="exitEditing">Cancel</v-btn>
          <v-btn :disabled="saveDisabled" @click="saveBusiness" color="primary"> Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "IRSForms",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    validForm: [],
    companies: [],
    quarters: [
      {
        year: 2020,
        quarter: 4,
        count: 1,
      },
      {
        year: 2021,
        quarter: 1,
        count: 1,
      },
      {
        year: 2021,
        quarter: 2,
        count: 1,
      },
      {
        year: 2021,
        quarter: 3,
        count: 1,
      },
    ],
    noOtherCompanies: false,
    rules: {
      ein: (value) => {
        const pattern = /^[0-9]{2}-[0-9]{7}$/;
        return pattern.test(value) || "Invalid ein";
      },
      required: (value) => !!value || "This field is required.",
    },
    loadingBusiness: false,
  }),
  methods: {
    exitEditing() {
      this.$emit("exitEditingOtherOwnership");
    },
    getPriorBusiness() {
      this.loadingBusiness = true;
      ClientsService.getPriorBusiness(this.client.id)
        .then((res) => {
          this.companies = res;
          if (!res.length) {
            this.noOtherCompanies = true;
            this.companies.push({ein: "", employees: JSON.parse(JSON.stringify(this.quarters))});
          }
        })
        .finally(() => {
          this.loadingBusiness = false;
        });
    },
    saveBusiness() {
      if (!this.companies[this.companies.length - 1].ein)
        this.companies.pop()

      if (this.noOtherCompanies)
        this.companies = []

      this.loadingBusiness = true;
      ClientsService.putPriorBusiness(this.client.id, this.companies)
        .then((res) => {
          this.getPriorBusiness();
          this.$emit("exitEditingOtherOwnership");
        })
        .finally(() => {
          this.loadingBusiness = false
        })
    },
    deleteCompany(i) {
      this.companies.splice(i ,1)
      this.validForm.splice(i, 1)
    }
  },
  created() {
    this.getPriorBusiness();
  },
  watch: {
    companies: {
      handler(val) {
        if (val[val.length - 1].ein && val[val.length - 1].ein.length == 10) {
          this.noOtherCompanies = false;
          this.companies.push({ ein: "", employees: JSON.parse(JSON.stringify(this.quarters)) });
        }
      },
      deep: true,
    },
  },
  computed: {
    saveDisabled() {
      return this.validForm.some(val => !val)
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>

