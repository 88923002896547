<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>Address</h3>
      </v-col>
    </v-row>
    <v-card width="500px" class="mt-6 pa-10">
      <v-card-text style="padding: 0">
        <v-form v-model="isValid">
          <v-text-field
            outlined
            :label="addressInputs[0].key"
            :rules="addressInputs[0].rules"
            v-model="client[addressInputs[0].refKey]"
          />
          <v-text-field
            outlined
            :label="addressInputs[1].key"
            :rules="addressInputs[1].rules"
            v-model="client[addressInputs[1].refKey]"
          />
          <v-row>
            <v-col>
              <v-select
                v-model="client[addressInputs[2].refKey]"
                :append-icon="'mdi-arrow-down'"
                outlined
                :rules="addressInputs[2].rules"
                :items="states"
                :item-text="'name'"
                :item-value="'abbreviation'"
                :label="addressInputs[2].key"
              />
            </v-col>
            <v-col>
              <v-text-field
                outlined
                :label="addressInputs[3].key"
                :rules="addressInputs[3].rules"
                v-model="client[addressInputs[3].refKey]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-10">
        <v-row justify="end">
          <v-btn class="mr-1" text @click="exitEditing"> Cancel </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="editClient" :loading="editLoading">
            Save
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { statesConstData } from "@/constants";

export default {
  name: "ClientAddressEditCard",
  props: ["addressInputs", "editLoading", "client"],
  data: () => ({
    isValid: true,
    selected: "",
    states: statesConstData,
  }),
  methods: {
    exitEditing() {
      this.$emit("exitEditingAddress");
    },
    editClient() {
      this.$emit("editClientAddress");
    },
  },
};
</script>
