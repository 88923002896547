<template>
  <v-container>
    <v-dialog max-width="600px" v-model="showDialog" persistent>
      <v-card v-if="round.value">
        <h3 style="background-color: #263b4f; color: white; padding: 12px; font-size: 0.9rem">
          Clear PPP {{ round.value.number }}
        </h3>
        <p style="font-weight: bold; font-size: 1rem; margin-left: 11px">
          Are you sure you would like to clear data for Round {{ round.value.number }}?
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <p></p>

          <v-btn color="blue darken-1" :loading="loading" text @click="deleteRound(round.value.id)">
            ACCEPT
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDialog"> CANCEL </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h3>{{ round.value && round.value.number ? `Edit Round ${round.value.number}` : "Add Round" }}</h3>
      </v-col>
    </v-row>
    <v-card v-if="round.value && round.value !== 'NO ROUNDS DECLARED!'" width="300px" class="mt-6 pa-6">
      <v-card-text style="padding: 0">
        <v-row>
          <v-col class="font-weight-bold">Loan Amount:</v-col>
          <v-col class="font-weight-medium">{{ round.value.amount }}</v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold">Loan Date:</v-col>
          <v-col v-if="round.value.start" class="font-weight-medium">{{ round.value.start_formatted.split(" ")[0] }}</v-col>
          <v-col v-else class="font-weight-medium">NOT RECEIVED</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-btn color="#1867C0" class="mt-5 text-decoration-underline" text @click="openDialog"
            >CLEAR</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-else width="500px" class="mt-6 pa-10">
      <v-card-text style="padding: 0">
        <v-form v-model="isValid">
          <v-text-field :disabled="didNotReceive" :rules="amountRules" v-model="roundForm.amount" prefix="$" />
          <v-text-field
            :disabled="didNotReceive"
            v-model="roundForm.start"
            label="Coverage Start Date:"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
          <v-date-picker
            color="primary"
            no-title
            v-model="roundForm.start"
            @input="menu = false"
            :min="minDate"
            :max="endDate"
          ></v-date-picker>
          <v-switch v-model="didNotReceive">
            <template v-slot:label>
              <div style="color: black">Did Not Receive This Round</div>
            </template></v-switch
          >
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-10">
        <v-row justify="end">
          <v-btn class="mr-1" text @click="exitEditing"> Cancel </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="saveRound" :loading="loading">
            Save
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ClientService from "@/services/clients.service";

export default {
  name: "ClientPPPRoundCard",
  data: () => ({
    didNotReceive: false,
    showDialog: false,
    isValid: false,
    loading: false,
    menu: false,
    roundForm: {
      amount: "",
      start: "2020-03-01",
    },
    minDate: "2020-01-01",
    endDate: "2020-12-31",
  }),
  props: ["round", "client"],
  methods: {
    exitEditing() {
      this.$emit("exitEditingRound");
    },
    saveRound() {
      this.loading = true;
      let roundData = {
        number: this.round.number,
        start:  this.didNotReceive ? null : this.roundForm.start.split(" ")[0],
        amount: this.roundForm.amount ? "$" + this.roundForm.amount : null,
        did_not_receive: this.didNotReceive,
      };
      return ClientService.addRound({
        clientId: this.client.id,
        round: roundData,
      })
        .then((data) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Round changes was saved !",
            duration: 5000,
          });
          this.loading = false;
          this.exitEditing();
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
          this.loading = false;
        });
    },
    deleteRound(id) {
      this.loading = true;
      return ClientService.deleteRound(id)
        .then(() => {
          this.loading = false;
          this.exitEditing();
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Round removed successfully`,
            duration: 4000,
            mode: "success",
          });
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
          this.loading = false;
        });
    },
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
  },
  mounted() {
    if (this.round.number == 2) {
      this.roundForm.start = "2021-01-01";
      this.minDate = "2021-01-01";
      this.endDate = "2021-12-31";
    }
  },
  computed: {
    amountRules() {
      if (!this.didNotReceive) {
        return [(value) => !!value || "Amount required"];
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped></style>
