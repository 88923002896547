<template>
  <v-sheet elevation="3" class="px-3 py-5">
    <p class="text-h6">{{ title }}</p>
    <v-card outlined>
      <v-list style="padding: 0">
        <v-list-item
          class="bordered"
          style="height: 55px"
          ripple
          link
          v-for="(item, i) in details"
          @click="
            () => {
              type === 'ertc' ? item.editFunc(item) : editItem(item);
            }
          "
        >
          <div style="width: 100%" class="d-flex align-center">
            <v-list-item-subtitle style="font-weight: 600; margin-right: 35px">
              <div class="key">
                <v-icon
                  dense
                  v-if="item.filled || item.tickStyle"
                  color="green"
                  :style="item.tickStyle && !item.filled ? item.tickStyle : ''"
                  >mdi-check-circle</v-icon
                >
                {{ item.key }}
              </div>
            </v-list-item-subtitle>
            <v-list-item-title v-if="item.value">
              <div v-if="type === 'ertc'" class="value">
                <div v-if="item.value.amount">
                  {{ item.value.amount === '$0.00' ? 'DID NOT RECEIVED' : item.value.amount }}
                </div>
                <div v-else>
                  {{ item.value }}
                </div>
              </div>
              <div v-else class="value">
                {{ item.value?.name ? item.value.name : item.value }}
              </div>
            </v-list-item-title>
            <v-list-item-action>
              <v-icon color="black">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </div>
        </v-list-item>
        <v-list-item v-if="type === 'ertc'" three-line class="bordered">
          <v-list-item-content>
            <v-list-item-title class="text-h5 font-weight-bold py-2">
              <div class="d-flex flex-column flex-md-row justify-space-between align-center">
                <span class="text-h6">Taxable social security wages</span>
                <v-switch
                  v-model="isTaxDisabled"
                  :disabled="wagesLoading"
                  id="social-security-wages-switch"
                  label="Edit"
                  color="#48A64C"
                  class="switchDraftOrFinal"
                  hide-details
                />
              </div>
            </v-list-item-title>
            <v-row
              class="d-flex flex-column justify-space-around"
              :style="{ height: '219px' }"
              v-if="wagesLoading"
            >
              <v-col cols="4" v-for="n of 3">
                <v-skeleton-loader type="text" />
                <v-skeleton-loader type="text" />
                <br />
                <br />
                <br />
                <v-skeleton-loader type="text" />
                <v-skeleton-loader type="text" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="4" v-for="(tax, index) in taxSocialSecurity" :key="'owner' + index">
                <v-text-field
                  class="number-text-field mb-1"
                  hide-details
                  v-if="!isTaxDisabled"
                  disabled
                  :value="tax.wages_formatted"
                />
                <v-text-field
                  v-else
                  v-model.number="tax.wages"
                  :value="tax.wages_formatted"
                  :id="`social-security-wage-input-${index}`"
                  required
                  class="number-text-field mb-1"
                  persistent-hint
                  color="black"
                  hide-details
                  :disabled="!isTaxDisabled || wagesLoading"
                  @blur="saveTaxSocialSecurity"
                />
                <p class="text-center text--secondary">
                  {{ hints[index] }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="bordered" v-if="type === 'ertc'" three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 font-weight-bold py-2">
              <div class="d-flex flex-column flex-md-row justify-space-between align-center">
                <span class="text-h6">Employee counts per quarter </span>
                <v-switch
                  :disabled="employeesLoading"
                  v-model="isEmployeeDisabled"
                  label="Edit"
                  color="#48A64C"
                  class="switchDraftOrFinal"
                  hide-details
                />
              </div>
            </v-list-item-title>
            <v-row
              class="d-flex flex-column justify-space-around"
              :style="{ height: '219px' }"
              v-if="employeesLoading"
            >
              <v-col cols="4" v-for="n of 3">
                <v-skeleton-loader type="text" />
                <v-skeleton-loader type="text" />
                <br />
                <br />
                <br />
                <v-skeleton-loader type="text" />
                <v-skeleton-loader type="text" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="4"
                v-for="(employeeCount, index) in employeeCounts"
                :key="'employee-counts' + index"
              >
                <v-text-field
                  :loading="employeesLoading"
                  v-model.number="employeeCounts[index].count"
                  required
                  persistent-hint
                  class="number-text-field mb-1"
                  color="black"
                  :disabled="!isEmployeeDisabled || employeesLoading"
                  hide-details
                  @blur="saveEmployeesAmounts"
                />
                <p class="text-center text--secondary">
                  {{ hints[index] }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-sheet>
</template>

<script>
import ClientService from "@/services/clients.service";
import ClientPackages from "@/components/Client/Profile/ClientPackages.vue";

export default {
  name: "ClientInformation",
  components: { ClientPackages },
  props: [
    "title",
    "details",
    "editItem",
    "type",
    "client",
  ],
  data: () => ({
    hints: ["2020-Q2", "2020-Q3","2020-Q4", "2021-Q1", "2021-Q2", "2021-Q3"],
    isDraft: false,
    generationInProgress: false,
    isEmployeeDisabled: false,
    isTaxDisabled: false,
    employeeCounts: [],
    taxSocialSecurity: [],
    wagesLoading: false,
    employeesLoading: false,
  }),
  methods: {
    async saveEmployeesAmounts() {
      let employeeToPost = Object.assign(
        {},
        this.employeeCounts.filter((employee) => employee.count != null)
      );
      this.employeesLoading = true;
      await ClientService.deleteEmployeesAmounts(this.client.id);
      await ClientService.saveEmployeesAmounts(this.client.id, Object.values(employeeToPost));
      await this.getEmployeePerQuarter();
    },
    async saveTaxSocialSecurity() {
      let payload = JSON.parse(
        JSON.stringify(this.taxSocialSecurity.filter((tax) => tax.wages != null))
      );
      payload.forEach((e) => delete e.wages_formatted);

      this.wagesLoading = true;
      await ClientService.deleteWages(this.client.id);
      await ClientService.saveWages(this.client.id, payload);
      await this.getWages();
    },
    getEmployeePerQuarter() {
      this.employeesLoading = true;
      return ClientService.getEmployeesAmounts(this.client.id)
        .then((res) => {
          res.forEach((e, index) => {
            this.employeeCounts[index] = e;
          });
        })
        .finally(() => (this.employeesLoading = false));
    },
    getWages() {
      this.wagesLoading = true;
      return ClientService.getWages(this.client.id)
        .then((res) => {
          this.taxSocialSecurity = res;
        })
        .finally(() => (this.wagesLoading = false));
    },
  },
  mounted() {
    if (this.type === "ertc") {
      this.getEmployeePerQuarter();
      this.getWages();
    }
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #00000017;
}

.value {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.key {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
</style>